import React, { useState, useEffect } from 'react'

import { Form, Input, Tabs, Menu, Dropdown, Card, Select, Divider, Button as AButton, Radio } from 'antd'
import { toast } from 'react-toastify'
import PubSub from 'pubsub-js'


import PageHeader from '../../components/PageHeader'
import Button from '../../components/Button'
import Drawer from '../../components/Drawer'
import Table from '../../components/Table'
import PageContent from '../../components/PageContent'
import Skeleton from '../../components/Skeleton'
import Utils from '../../components/Utils'
import DecisionModal from '../../components/DecisionModal'
import OpeningHours from '../../components/OpeningHours'
import Icon from '../../components/Icon'
import Thumbnail from '../../components/Thumbnail'
import Tag from '../../components/Tag'

import MediaLibrary from '../../applets/MediaLibrary'
import HSPCategoryCreator from '../../applets/HSPCategories/HSPCategoryCreator'
import HSPCategories from '../../applets/HSPCategories'

import { LangContext } from '../../components/Translation'
import translations from './translations'

import { useGlobalContext } from '../../components/AppContext'

import Backend from '../../api/Backend'

import { CMS_ENDPOINT } from '../../pages/CMS'

import './index.css'
import { CategorySelectCard, CategorySelector } from '../../components/CategorySelector/CategorySelector'
import { CATEGORY_TYPES } from '../../pages/HSPDashboard'
import AllowRender from '../../components/AllowRender'

const contexts = {
	HP_LIST: 'hp_list',
	HP_CATEGORIES: 'categories'
}

const action = {
	DISABLE_HSP: 'disable-hsp',
	RESET_PASSWORD: 'reset-password',
	EDIT_HSP: 'edit-hsp',
	CHAT: 'CHAT'
}

const HSP_TABLE_FILTER_LOCALSTORAGE_KEY = 'CMS_TABLE_COLUMN_FILTER'


const populateHoursArray = (sunday_hours, monday_hours, tuesday_hours, wednesday_hours, thursday_hours, friday_hours, saturday_hours) => {
	var available = []
	if (monday_hours && monday_hours.length > 0) {
		for (let i of monday_hours) {
			available.push({ weekday: 'MONDAY', from: i.opening, till: i.closing })
		}
	}
	if (tuesday_hours && tuesday_hours.length > 0) {
		for (let i of tuesday_hours) {
			available.push({ weekday: 'TUESDAY', from: i.opening, till: i.closing })
		}
	}
	if (wednesday_hours && wednesday_hours.length > 0) {
		for (let i of wednesday_hours) {
			available.push({ weekday: 'WEDNESDAY', from: i.opening, till: i.closing })
		}
	}
	if (thursday_hours && thursday_hours.length > 0) {
		for (let i of thursday_hours) {
			available.push({ weekday: 'THURSDAY', from: i.opening, till: i.closing })
		}
	}
	if (friday_hours && friday_hours.length > 0) {
		for (let i of friday_hours) {
			available.push({ weekday: 'FRIDAY', from: i.opening, till: i.closing })
		}
	}
	if (saturday_hours && saturday_hours.length > 0) {
		for (let i of saturday_hours) {
			available.push({ weekday: 'SATURDAY', from: i.opening, till: i.closing })
		}
	}
	if (sunday_hours && sunday_hours.length > 0) {
		for (let i of sunday_hours) {
			available.push({ weekday: 'SUNDAY', from: i.opening, till: i.closing })
		}
	}

	return available
}

const HPList = ({ newContent, setNewContent, healthProviders, loading, cmsSubcategories, hspStatuses, hspSubcategories }) => {
	const backend = new Backend()
	const lang = React.useContext(LangContext)(translations)
	const utils = new Utils()

	const [showEditHSP, setShowEditHSP] = useState(false)
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
	const [resetPassword, setResetPassword] = useState(false)

	const [openThumbnailMediaLibrary, setOpenThumbnailMediaLibrary] = useState(false)
	const [selectedThumbnailUrl, setSelectedThumbnailUrl] = useState()

	const [activeContentCategories, setActiveContentCategories] = useState([])
	const [activeHSPCategories, setActiveHSPCategories] = useState([])

	const [requestedContentCategories, setRequestedContentCategories] = useState([])
	const [requestedHSPCategories, setRequestedHSPCategories] = useState([])
	const [localStorageFilter, setLocalStorageFilter] = useState({})
	const [isLocalStorageReset, setIsLocalStorageReset] = useState(false)

	const [selectedCard, setSelectedCard] = useState()

	const [HSPForm] = Form.useForm()
	const rules = [
		{
			required: true,
			message: lang('missing-input')
		}
	]


	useEffect(() => {
		let persistedFilterValue = JSON.parse(window.localStorage.getItem(HSP_TABLE_FILTER_LOCALSTORAGE_KEY))
		if (persistedFilterValue) {
			setLocalStorageFilter(persistedFilterValue)
		}
	}, [isLocalStorageReset])

	useEffect(() => {
		window.localStorage.setItem(HSP_TABLE_FILTER_LOCALSTORAGE_KEY, JSON.stringify(localStorageFilter))
	}, [localStorageFilter])

	useEffect(() => {
		setRequestedContentCategories(showEditHSP?.requested_content_categories)
		setRequestedHSPCategories(showEditHSP?.requested_hsp_categories)
		populateActiveHSPCategories()
		populateActiveCMSCategories()
		showEditHSP?.level && setSelectedCard(showEditHSP?.level.toString())
		showEditHSP?.level && HSPForm.setFieldsValue({ level: showEditHSP?.level.toString() })
	}, [showEditHSP])

	useEffect(() => {
		HSPForm.resetFields()
		setSelectedThumbnailUrl(null)
	}, [HSPForm, showEditHSP])

	const handleCardSelect = ({ card }) => {
		setSelectedCard(card)
		HSPForm.setFieldsValue({ level: card })
	}

	const populateActiveHSPCategories = () => {
		setActiveHSPCategories(
			hspSubcategories.filter((subcat) => {
				if (subcat.item.some((item) => parseInt(item.link.split('/')[2]) === showEditHSP?.id)) {
					return true
				}
			})
		)
	}

	const populateActiveCMSCategories = () => {
		setActiveContentCategories(
			cmsSubcategories.filter((subcat) => {
				if (subcat.item.some((item) => item.link.split('/')[1] === 'hsps' && parseInt(item.link.split('/')[2]) === showEditHSP?.id)) {
					return true
				}
			})
		)
	}

	const editHSPForm = (row) => {
		return (
			<>
				<Form form={HSPForm} initialValues={row} layout="vertical">
					<div className="thumbnail-container">
						<div>
							<div className="ant-col ant-form-item-label">
								<label htmlFor="thumbnail" title="Thumbnail" className="ant-form-item-required">
									{lang('thumbnail')}
								</label>
							</div>
							<Form.Item name="new_display_picture" hidden>
								<Input type={'hidden'} />
							</Form.Item>
							<br />
							<Thumbnail
								url={
									selectedThumbnailUrl
										? selectedThumbnailUrl
										: row?.image?.formats
										? `${CMS_ENDPOINT}` + row?.image?.formats?.thumbnail?.url
										: row?.image?.url
										? `${CMS_ENDPOINT}` + row?.image?.url
										: null
								}
							/>
						</div>
						<div>
							<Button type="secondary" txt={lang('select-thumbnail')} onClick={() => setOpenThumbnailMediaLibrary(true)} />
						</div>
					</div>
					<Drawer
						open={openThumbnailMediaLibrary}
						placement="right"
						onClose={() => {
							setOpenThumbnailMediaLibrary(false)
						}}
						width={'65%'}
						text={{ title: lang('select-thumbnail'), cancel: lang('cancel') }}>
						<MediaLibrary
							single
							media={{
								onMediaSelect: () => setOpenThumbnailMediaLibrary(false),
								setSelectedMedia: (thumbnail) => {
									HSPForm.setFieldsValue({
										new_display_picture: {
											id: thumbnail.id,
											url: thumbnail?.formats?.thumbnail ? thumbnail.formats.thumbnail.url : thumbnail.url
										}
									})
								},
								setSelectedMediaUrl: setSelectedThumbnailUrl,
								type: 'Thumbnail',
								mime: ['image/jpeg', 'image/png', 'image/svg+xml']
							}}
						/>
					</Drawer>

					<Form.Item initialValue={row.name} name="HP_name" label={lang('institute')} rules={rules}>
						<Input />
					</Form.Item>
					<Form.Item
						name="email"
						label="Email"
						rules={[
							{
								required: true,
								type: 'email',
								message: lang('email_format')
							}
						]}>
						<Input disabled={true} />
					</Form.Item>

					<Form.Item name="summary" initialValue={row?.summary} label={lang('summary')}>
						<Input.TextArea showCount rows={3} maxLength={280} />
					</Form.Item>

					<Form.Item name="status" label={lang('status')} rules={rules}>
						<Select disabled={true}>
							{Object.values(hspStatuses).map((item, index) => {
								return (
									<Select.Option key={index} value={item}>
										{item}
									</Select.Option>
								)
							})}
						</Select>
					</Form.Item>
					<Form.Item name="national_switch" initialValue={row?.nationwide} label={lang('nationwide')}>
						<Select>
							<Select.Option value={false}>{lang('no')}</Select.Option>
							<Select.Option value={true}>{lang('yes')}</Select.Option>
						</Select>
					</Form.Item>

					{!row?.nationwide && (
						<>
							<Divider />
							<Input.Group>
								<div className="doubleFormItem">
									<div style={{ flexGrow: 1, width: '70%' }}>
										<Form.Item name="street" label={lang('street-name')}>
											<Input />
										</Form.Item>
									</div>
									<div style={{ flexGrow: 1 }}>
										<Form.Item name="streetnr" label={lang('street-number')}>
											<Input />
										</Form.Item>
									</div>
								</div>
							</Input.Group>

							<Form.Item
								name="zip"
								label={lang('zip-code')}
								rules={[
									{ required: true, message: lang('missing-zip-code') },
									{
										validator(_, value) {
											if (!value.match(/^\d{5}$/)) {
												return Promise.reject(lang('invalid-zip-code'))
											} else {
												return Promise.resolve()
											}
										}
									}
								]}>
								<Input />
							</Form.Item>
							<Form.Item name="city" label={lang('city')}>
								<Input />
							</Form.Item>
						</>
					)}
					<Form.Item name="phone" label={lang('phone-number')}>
						<Input />
					</Form.Item>

					<Form.Item name="website" label={lang('website')}>
						<Input />
					</Form.Item>

					<Divider />

					<Form.Item name="service" label={lang('service')}>
						<Input.TextArea rows={8} />
					</Form.Item>

					<Divider />

					<div className="hsp-profile-subsection">
						<h2>{lang('hsp-level')}</h2> <div>{lang('hsp-level-explanation')}</div>
					</div>
					<div style={{ marginLeft: '35%' }} className="hsp-level-select">
						<Card
							className={selectedCard === '1' ? 'selected-level' : ''}
							headStyle={{ fontSize: '3em', textAlign: 'center' }}
							title={lang('level-1')}
							hoverable
							onClick={() => handleCardSelect({ card: '1' })}>
							<div className="center-icons">
								<Icon type="mama-hands" className="mama-no-outline" />
							</div>
							<div className="hsp-level-body">
								<div className="hsp-features">
									<ul>
										<li>{lang('level-1-features')}</li>
									</ul>
								</div>
							</div>
							<AButton type="primary" block>
								{lang('select')}
							</AButton>
						</Card>
						{/* <Card
							className={selectedCard === '2' ? 'selected-level' : ''}
							title={lang('level-2')}
							headStyle={{ fontSize: '3em', textAlign: 'center' }}
							hoverable
							onClick={() => handleCardSelect({ card: '2' })}>
							<div className="center-icons">
								<Icon type="mama-hands" className="mama-no-outline" />
								<Icon type="mama-chat" />
								<Icon type="mama-call" className="mama-outline" />
							</div>
							<div className="hsp-level-body">
								<div className="hsp-features">
									<ul>
										<li>{lang('level-1-features')}</li>
										<li>{lang('level-2-features')}</li>
									</ul>
								</div>
							</div>
							<AButton type="primary" block>
								{lang('select')}
							</AButton>
						</Card> */}
					</div>
					<Form.Item
						hidden
						name="level"
						initialValue={row?.level}
						rules={[
							{
								required: true,
								message: lang('missing-input')
							}
						]}>
						<Input hidden />
					</Form.Item>
					<Divider />

					<label
						style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}
						title="selected-categories"
						className="ant-form-item-required">
						<h1>{lang('opening-hours')}</h1>
					</label>
					<div className="hours-container">
						<OpeningHours day="monday" initialValue={row.available} />
						<OpeningHours day="tuesday" initialValue={row.available} />
						<OpeningHours day="wednesday" initialValue={row.available} />
						<OpeningHours day="thursday" initialValue={row.available} />
						<OpeningHours day="friday" initialValue={row.available} />
						<OpeningHours day="saturday" initialValue={row.available} />
						<OpeningHours day="sunday" initialValue={row.available} />
					</div>
					<Divider />

					{row.status?.toLowerCase() === lang('display-' + hspStatuses.INACTIVE.toLowerCase()) && (
						<>
							<Divider />
							<Card>
								<label
									style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}
									title="selected-categories"
									className="ant-form-item-required">
									<h1>{lang('selected-categories')}</h1>
								</label>
								<div className="category-select-card">
									<CategorySelector
										setSelection={setRequestedHSPCategories}
										selection={requestedHSPCategories}
										type={CATEGORY_TYPES.HSP}
									/>
									<CategorySelector
										setSelection={setRequestedContentCategories}
										selection={requestedContentCategories}
										type={CATEGORY_TYPES.CONTENT}
									/>
								</div>
							</Card>
						</>
					)}

					{row?.status?.toLowerCase() !== lang('display-' + hspStatuses.INACTIVE.toLowerCase()) && (
						<Card>
							<label
								style={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}
								title="selected-categories"
								className="ant-form-item-required">
								<h1>{lang('selected-categories')}</h1>
							</label>
							<div className="category-select-card">
								<CategorySelector setSelection={setActiveHSPCategories} selection={activeHSPCategories} type={CATEGORY_TYPES.HSP} />
								<CategorySelector
									setSelection={setActiveContentCategories}
									selection={activeContentCategories}
									type={CATEGORY_TYPES.CONTENT}
								/>
							</div>
						</Card>
					)}
				</Form>
			</>
		)
	}

	const renderActions = ({ row }) => {
		const handleMenuClick = (e) => {
			if (e.key === action.DISABLE_HSP) {
				setShowDeleteConfirm(row)
			} else if (e.key === action.RESET_PASSWORD) {
				setResetPassword(row)
			} else if (e.key === action.EDIT_HSP) {
				setShowEditHSP(row)
			}else if(e.key === action.CHAT) {
				PubSub.publish('chat:start', { hspId: row.id, hspName: row.name })
			}
		}

		const menu = (
			<Menu onClick={handleMenuClick}>
				{
					<>
						<Menu.Item disabled={row.originalStatus === hspStatuses.CANCELLED} key={action.CHAT}>
							<a target="_blank" rel="noopener noreferrer">
								{lang('chat')}
							</a>
						</Menu.Item>
						<Menu.Item key={action.EDIT_HSP}>
							<a target="_blank" rel="noopener noreferrer">
								{lang('edit-hsp')}
							</a>
						</Menu.Item>
						<Menu.Item disabled={row.originalStatus === hspStatuses.CANCELLED} key={action.RESET_PASSWORD}>
							<a target="_blank" rel="noopener noreferrer">
								{lang('reset-password')}
							</a>
						</Menu.Item>
						<Menu.Item disabled={row.originalStatus === hspStatuses.CANCELLED} key={action.DISABLE_HSP}>
							<a target="_blank" rel="noopener noreferrer">
								{lang('cancel-hsp')}
							</a>
						</Menu.Item>
					</>
				}
			</Menu>
		)

		return (
			<>
				<Dropdown overlay={menu}>
					<a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
						{lang('actions')}
					</a>
				</Dropdown>
			</>
		)
	}

	
	const resetHSPPw = (row) => {
		if (!row) return
		const body = JSON.stringify({ email: row.email })
		const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })

		backend.healthProvider({
			id: row.id,
			type: backend.type.CREATE,
			passwordReset: true,
			body,
			cb: () => {
				toast.update(loadingToast, { render: lang('password_reset_success'), type: 'success', isLoading: false, autoClose: true })
			},
			cbError: () => {
				toast.update(loadingToast, { render: lang('password_reset_error'), type: 'error', isLoading: false, autoClose: true })
			}
		})
	}

	const columns = [
		{
			value: lang('image'),
			key: 'image',
			render: (url) => {
				if (
					!url?.value ||
					url?.value?.formats?.thumbnail?.url.includes('cmsundefined') ||
					url?.value?.formats?.thumbnail?.url.includes('cmsnull') ||
					url?.value?.url.includes('cmsundefined') ||
					url?.value?.url.includes('cmsnull')
				) {
					return <Icon type="missing-image" style={{ width: '100%', height: '100%' }} />
				}
				const resolvedURL = url?.value?.formats ? url?.value?.formats?.thumbnail?.url : url?.value?.url
				return <img src={CMS_ENDPOINT + resolvedURL} className="cms-image"></img>
			}
		},
		{
			value: lang('institute'),
			key: 'name',
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.name.localeCompare(b.name),
			defaultFilteredValue: localStorageFilter?.name ? [localStorageFilter?.name || ''] : null,
			search: true
		},
		{
			value: lang('email'),
			key: 'email',
			sorter: (a, b) => a.email.localeCompare(b.email),
			defaultFilteredValue: localStorageFilter?.email ? [localStorageFilter?.email || ''] : null,
			search: true
		},
		{
			value: lang('status'),
			key: 'status',
			sorter: (a, b) => a.status.localeCompare(b.status),
			render: ({value}) => {
				return (
					<>
						<Tag type={value}>
							{value?.toUpperCase()}
						</Tag>
					</>
				)
			},
			defaultFilteredValue: localStorageFilter?.status ? [localStorageFilter?.status || ''] : null,
			searchWithRender: true
		},
		{
			value: lang('updated-date'),
			key: 'updated_at',
			sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
			render: (date) => utils.toDate({ dateString: date.value })
		},
		{ key: 'actions', value: lang('actions'), fixed: 'right', render: renderActions }
	]



	return (
		<>
			<Card
				title={lang('hsp-list')}
				extra={
					<div className="card-head-action-container">
						<Button
							className="ant-btn-primary btn-refresh"
							onClick={() => setNewContent((newContent) => newContent + 1)}
							icon={<Icon type={'refresh'} />}
						/>
						<AllowRender permissions={['HSP_MANAGER_RW']}>
							<Button
								disabled={Object.values(localStorageFilter).length < 1}
								style={{ marginLeft: '10px' }}
								type="filter-reset"
								onClick={() => {
									localStorage.clear()
									setIsLocalStorageReset(true)
									setLocalStorageFilter({})
								}}>
								{lang('reset-filters')}
							</Button>
						</AllowRender>
					</div>
				}>
				<Table
					columns={columns}
					data={healthProviders}
					localStorageDataKey={HSP_TABLE_FILTER_LOCALSTORAGE_KEY}
					pagination={{
						defaultPageSize: 10,
						pageSizeOptions: [10, 20, 50, 100],
						showSizeChanger: true
					}}
					setLocalStorageFilter={setLocalStorageFilter}
					loading={loading}
				/>
			</Card>
			<Drawer
				width={'95%'}
				open={showEditHSP}
				text={{ title: lang('edit-hsp'), ok: lang('save'), cancel: lang('cancel'), extra: lang('approve-hsp') }}
				onClose={() => setShowEditHSP(false)}
				disabled = {showEditHSP?.originalStatus === hspStatuses.CANCELLED}
				extraDisabled={!(showEditHSP?.status?.toLowerCase() === lang('display-' + hspStatuses.INACTIVE.toLowerCase()))}
				onExtra={() => {
					HSPForm.validateFields()
						.then((values) => {
							let preselectedHSPCategories =
								requestedHSPCategories &&
								requestedHSPCategories.map((category) => {
									return {
										id: category.id
									}
								})
							let preselectedContentCategories =
								requestedContentCategories &&
								requestedContentCategories.map((category) => {
									return {
										id: category.id
									}
								})

							hspSubcategories.forEach((subcategory) => {
								let match = preselectedHSPCategories.find((category) => parseInt(category.id) === subcategory.id)
								if (match) {
									// Add it to subcategory, if its not in there already.
									let alreadyInSubcategory = subcategory?.item.find((hsp) => showEditHSP.id === parseInt(hsp.link.split('/')[2]))
									if (!alreadyInSubcategory) {
										let new_content = subcategory.item.map((hsp) => parseInt(hsp.link.split('/')[2]))
										new_content.push(showEditHSP.id)
										let body = { title: subcategory.title, new_content: new_content }
										body = JSON.stringify(body)
										backend.HSPCategories({
											type: backend.type.UPDATE,
											id: subcategory.id,
											body: body
										})
									}
								} else {
									// Remove it from subcategory, if it is in there.
									let hspIsInExtraCategory = subcategory?.item.find((hsp) => showEditHSP.id === parseInt(hsp.link.split('/')[2]))
									if (hspIsInExtraCategory) {
										let new_content = subcategory.item.filter((hsp) => hsp.link !== hspIsInExtraCategory.link)
										let body = { title: subcategory.title, new_content: new_content }
										body = JSON.stringify(body)
										backend.HSPCategories({
											type: backend.type.UPDATE,
											id: subcategory.id,
											body: body
										})
									}
								}
							})

							cmsSubcategories.forEach((subcategory) => {
								let match = preselectedContentCategories.find((category) => parseInt(category.id) === subcategory.id)
								if (match) {
									// Add it to subcategory, if its not in there already.
									let alreadyInSubcategory = subcategory?.item.find(
										(hsp) => showEditHSP.id === parseInt(hsp.link.split('/')[2]) && hsp.link.split('/')[1] === 'hsps'
									)
									if (!alreadyInSubcategory) {
										let new_content = subcategory.item.map((hsp) => {
											return { key: parseInt(hsp.link.split('/')[2]), type: { title: hsp.link.split('/')[1] } }
										})
										new_content.push({ key: showEditHSP.id, type: { title: 'hsps' } })
										let body = { title: subcategory.title, new_content: new_content }
										body = JSON.stringify(body)
										backend.cmsCategories({
											type: backend.type.UPDATE,
											id: subcategory.id,
											body: body
										})
									}
								} else {
									// Remove it from subcategory, if it is in there.
									let hspIsInExtraCategory = subcategory?.item.find(
										(hsp) => showEditHSP.id === parseInt(hsp.link.split('/')[2]) && hsp.link.split('/')[1] === 'hsps'
									)
									if (hspIsInExtraCategory) {
										let new_content = subcategory.item.filter((hsp) => hsp.link !== hspIsInExtraCategory.link)
										let body = { title: subcategory.title, new_content: new_content }
										body = JSON.stringify(body)
										backend.cmsCategories({
											type: backend.type.UPDATE,
											id: subcategory.id,
											body: body
										})
									}
								}
							})

							const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
							let newBody = {
								name: values.HP_name,
								website: values.website,
								street: values.street,
								streetnr: values.streetnr,
								zip: values.zip,
								city: values.city,
								phone: values.phone,
								email: values.email,
								service: values.service,
								summary: values.summary,
								requested_hsp_categories: preselectedHSPCategories,
								requested_content_categories: preselectedContentCategories,
								available: populateHoursArray(
									values.sunday_hours,
									values.monday_hours,
									values.tuesday_hours,
									values.wednesday_hours,
									values.thursday_hours,
									values.friday_hours,
									values.saturday_hours
								),
								image: values?.new_display_picture?.id
									? values.new_display_picture.id
									: showEditHSP?.image?.id
									? showEditHSP.image.id
									: null,
								status: hspStatuses.ACTIVE,
								level: values.level,
								nationwide: values.national_switch
							}

							backend.healthProvider({
								id: showEditHSP.id,
								type: backend.type.UPDATE,
								body: JSON.stringify(newBody),
								cb: () => {
									toast.update(loadingToast, {
										render: lang('hsp-activated'),
										type: 'success',
										isLoading: false,
										closeOnClick: true,
										autoClose: 5000
									})
									setNewContent(newContent + 1)
									setShowEditHSP(false)
								},
								cbError: (err) => {
									toast.update(loadingToast, {
										render: err?.message || lang('hsp-activation-failed'),
										type: 'error',
										isLoading: false,
										closeOnClick: true,
										autoClose: 5000
									})
								}
							})
							setSelectedThumbnailUrl(null)
							setShowEditHSP(false)
						})
						.catch((err) => toast.error(err.errorFields[0].errors[0]))
				}}
				onOK={() => {
					HSPForm.validateFields()
						.then((values) => {
							let fields = HSPForm.getFieldsValue()
							let preselectedHSPCategories =
								activeHSPCategories &&
								activeHSPCategories.map((category) => {
									return {
										id: category.id
									}
								})
							let preselectedContentCategories =
								activeContentCategories &&
								activeContentCategories.map((category) => {
									return {
										id: category.id
									}
								})
							// HSP categories
							hspSubcategories.forEach((subcategory) => {
								let match = activeHSPCategories.find((category) => parseInt(category.id) === subcategory.id)
								if (match) {
									// Add it to subcategory, if its not in there already.
									let alreadyInSubcategory = subcategory?.item.find((hsp) => showEditHSP.id === parseInt(hsp.link.split('/')[2]))
									if (!alreadyInSubcategory) {
										let new_content = subcategory.item.map((hsp) => parseInt(hsp.link.split('/')[2]))
										new_content.push(showEditHSP.id)
										let body = { title: subcategory.title, new_content: new_content }
										body = JSON.stringify(body)
										backend.HSPCategories({
											type: backend.type.UPDATE,
											id: subcategory.id,
											body: body
										})
									}
								} else {
									// Remove it from subcategory, if it is in there.
									let hspIsInExtraCategory = subcategory?.item.find((hsp) => showEditHSP.id === parseInt(hsp.link.split('/')[2]))
									if (hspIsInExtraCategory) {
										let new_content = subcategory.item.filter((hsp) => hsp.link !== hspIsInExtraCategory.link)
										let body = { title: subcategory.title, new_content: new_content }
										body = JSON.stringify(body)
										backend.HSPCategories({
											type: backend.type.UPDATE,
											id: subcategory.id,
											body: body
										})
									}
								}
							})
							// Content categories
							cmsSubcategories.forEach((subcategory) => {
								let match = activeContentCategories.find((category) => parseInt(category.id) === subcategory.id)
								if (match) {
									// Add it to subcategory, if its not in there already.
									let alreadyInSubcategory = subcategory?.item.find(
										(hsp) => showEditHSP.id === parseInt(hsp.link.split('/')[2]) && hsp.link.split('/')[1] === 'hsps'
									)
									if (!alreadyInSubcategory) {
										let new_content = subcategory.item.map((hsp) => {
											return { key: parseInt(hsp.link.split('/')[2]), type: { title: hsp.link.split('/')[1] } }
										})
										new_content.push({ key: showEditHSP.id, type: { title: 'hsps' } })
										let body = { title: subcategory.title, new_content: new_content }
										body = JSON.stringify(body)
										backend.cmsCategories({
											type: backend.type.UPDATE,
											id: subcategory.id,
											body: body
										})
									}
								} else {
									// Remove it from subcategory, if it is in there.
									let hspIsInExtraCategory = subcategory?.item.find(
										(hsp) => showEditHSP.id === parseInt(hsp.link.split('/')[2]) && hsp.link.split('/')[1] === 'hsps'
									)
									if (hspIsInExtraCategory) {
										let new_content = subcategory.item
											.filter((hsp) => hsp.link !== hspIsInExtraCategory.link)
											.map((hsp) => ({ ...hsp, type: { strapiKey: hsp.link.split('/')[1] } }))
										let body = { title: subcategory.title, new_content: new_content }
										body = JSON.stringify(body)
										backend.cmsCategories({
											type: backend.type.UPDATE,
											id: subcategory.id,
											body: body
										})
									}
								}
							})

							const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
							let newBody = {
								name: fields.HP_name,
								website: fields.website,
								street: fields.street,
								streetnr: fields.streetnr,
								zip: fields.zip,
								city: fields.city,
								phone: fields.phone,
								email: fields.email,
								service: fields.service,
								summary: fields.summary,
								requested_hsp_categories: preselectedHSPCategories,
								requested_content_categories: preselectedContentCategories,
								available: populateHoursArray(
									fields.sunday_hours,
									fields.monday_hours,
									fields.tuesday_hours,
									fields.wednesday_hours,
									fields.thursday_hours,
									fields.friday_hours,
									fields.saturday_hours
								),
								image: fields?.new_display_picture?.id
									? fields.new_display_picture.id
									: showEditHSP?.image?.id
									? showEditHSP.image.id
									: null,
								status: healthProviders.find(hp => hp.name === values.HP_name).originalStatus,
								level: values.level,
								nationwide: values.national_switch
							}

							backend.healthProvider({
								id: showEditHSP.id,
								type: backend.type.UPDATE,
								body: JSON.stringify(newBody),
								cb: () => {
									toast.update(loadingToast, {
										render: lang('hsp-updated'),
										type: 'success',
										isLoading: false,
										closeOnClick: true,
										autoClose: 5000
									})
									setNewContent(newContent + 1)
									setShowEditHSP(false)
								},
								cbError: () => {
									toast.update(loadingToast, {
										render: lang('hsp-update-failed'),
										type: 'error',
										isLoading: false,
										closeOnClick: true,
										autoClose: 5000
									})
								}
							})
							setSelectedThumbnailUrl(null)
							setShowEditHSP(false)
						})
						.catch((err) => toast.error(err.errorFields[0].errors[0]))
				}}>
				{editHSPForm(showEditHSP)}
			</Drawer>
			<DecisionModal
				autoClose={true}
				open={showDeleteConfirm}
				onCancel={() => setShowDeleteConfirm(false)}
				text={{
					title: lang('delete-confirm', { title: showDeleteConfirm.name }),
					ok: lang('disable'),
					cancel: lang('cancel')
				}}
				onOK={() => {
					const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
					let newBody = {
						status: hspStatuses.CANCELLED,
					}
					backend.healthProvider({
						id: showDeleteConfirm.id,
						type: backend.type.UPDATE,
						body: JSON.stringify(newBody),
						cb: () => {
							toast.update(loadingToast, {
								render: lang('hsp-cancelled'),
								type: 'success',
								isLoading: false,
								closeOnClick: true,
								autoClose: 5000
							})
							setNewContent(newContent + 1)
						},
						cbError: () => {
							toast.update(loadingToast, {
								render: lang('hsp-cancel-failed'),
								type: 'error',
								isLoading: false,
								closeOnClick: true,
								autoClose: 5000
							})
						}
					})
					setShowDeleteConfirm(false)
				}}
			/>
			<DecisionModal
				bordered
				open={resetPassword}
				onOK={() => {
					resetHSPPw(resetPassword)
					setResetPassword(false)
				}}
				onCancel={() => setResetPassword(false)}
				text={{
					title: lang('reset-password'),
					ok: lang('reset'),
					cancel: lang('cancel'),
					details: `${lang('reset_password_for', [resetPassword?.name])}?`
				}}
			/>
		</>
	)
}

const HealthServiceProviders = () => {
	const [context, setContext] = useState(contexts.HP_LIST)

	const [healthProviders, setHealthProviders] = useState()
	const [loading, setLoading] = useState(false)

	const [openOnboardHP, setOpenOnboardHP] = useState(false)
	const [fetchCategories, setFetchCategories] = useState(1)

	const [selectedThumbnailUrl, setSelectedThumbnailUrl] = useState()
	const [openThumbnailMediaLibrary, setOpenThumbnailMediaLibrary] = useState(false)

	const [updating, setUpdating] = useState(false)

	const [newContent, setNewContent] = useState(0)

	const [categoryForm] = Form.useForm()
	const [HSPCreationForm] = Form.useForm()

	const [openCreateCategory, setOpenCreateCategory] = useState(false)
	const [resetCategoryForm, setResetCategoryForm] = useState(100000)

	const [requestedHSPCategories, setRequestedHSPCategories] = useState([])
	const [requestedContentCategories, setRequestedContentCategories] = useState([])

	const [selectedCard, setSelectedCard] = useState('2')
	const [nationwideRadio, setNationwideRadio] = useState(false)
	const [hspSubcategories, setHspSubcategories] = useState([])
	const [cmsSubcategories, setCmsSubcategories] = useState([])

	const backend = new Backend()
	const lang = React.useContext(LangContext)(translations)
	const { globals, hspStatuses } = useGlobalContext()


	useEffect(() => {
		backend.HSPSubcategories({
			cache: false,
			cb: (subcat) => {
				setHspSubcategories(subcat)
			}
		})
		backend.cmsSubcategories({
			cache: false,
			cb: (subcat) => {
				setCmsSubcategories(subcat)
			}
		})
	}, [newContent])

	useEffect(() => {
		HSPCreationForm.resetFields()
		setSelectedThumbnailUrl(null)
	}, [HSPCreationForm, openOnboardHP, resetCategoryForm])

	useEffect(() => {
		setLoading(true)
		setRequestedContentCategories([])
		setRequestedHSPCategories([])
		setSelectedCard('2')
		if (globals?.selectedCenter) {
			backend.healthProvider({
				cache: false,
				clinicId: globals?.selectedCenter,
				cb: (data) => {
					setHealthProviders(
						data &&
							data.map((provider) => {
								return { ...provider, status: lang('display-' + provider.status.toLowerCase()), originalStatus: provider.status.toUpperCase()}
							})
					)
					setLoading(false)
				}
			})
		}
	}, [newContent, globals.selectedCenter])

	const contextSwitcherTab = () => {
		return (
			<div>
				<Tabs
					style={{ marginBottom: '-10px' }}
					defaultActiveKey="1"
					size="large"
					onChange={(key) => {
						if (key === '1') {
							setContext(contexts.HP_LIST)
						} else if (key === '2') {
							setContext(contexts.HP_CATEGORIES)
						}
					}}>
					<Tabs.TabPane tab={lang('hp-list')} key="1" />
					<Tabs.TabPane tab={lang('edit-categories')} key="2" />
				</Tabs>
			</div>
		)
	}

	const rules = [
		{
			required: true,
			message: lang('missing-input')
		}
	]

	const handleCardSelect = ({ card }) => {
		setSelectedCard(card)
		HSPCreationForm.setFieldsValue({ level: card })
	}

	const handleRadioSelect = (radio) => {
		setNationwideRadio(radio.target.value)
		HSPCreationForm.setFieldsValue({ national_switch: radio.target.value })
	}

	const onboardingForm = () => (
		<>
			<div className="thumbnail-container">
				<div>
					<div className="ant-col">
						<label htmlFor="thumbnail" title="Thumbnail">
							{lang('thumbnail')}
						</label>
					</div>
					<Thumbnail url={selectedThumbnailUrl} />
				</div>
				<div>
					<Button type="primary" txt={lang('select-thumbnail')} onClick={() => setOpenThumbnailMediaLibrary(true)} />
				</div>
			</div>
			<Drawer
				open={openThumbnailMediaLibrary}
				placement="right"
				onClose={() => {
					setOpenThumbnailMediaLibrary(false)
				}}
				width={'85%'}
				text={{ title: lang('select-thumbnail'), cancel: lang('cancel') }}>
				<MediaLibrary
					single
					media={{
						onMediaSelect: () => setOpenThumbnailMediaLibrary(false),
						setSelectedMedia: (thumbnail) => {
							HSPCreationForm.setFieldsValue({
								new_display_picture: {
									id: thumbnail.id,
									url: thumbnail?.formats?.thumbnail ? thumbnail.formats.thumbnail.url : thumbnail.url
								}
							})
						},
						setSelectedMediaUrl: setSelectedThumbnailUrl,
						type: 'Thumbnail',
						mime: ['image/jpeg', 'image/png', 'image/svg+xml']
					}}
				/>
			</Drawer>
			<Form form={HSPCreationForm} layout="vertical">
				<Form.Item name="new_display_picture" />
				<Form.Item name="HP_name" label={lang('institute')} rules={rules}>
					<Input />
				</Form.Item>
				<Form.Item
					name="email"
					label="Email"
					rules={[
						{
							required: true,
							type: 'email',
							message: lang('email_format')
						}
					]}>
					<Input />
				</Form.Item>
				<Form.Item name="status" label={lang('status')} rules={rules} initialValue={hspStatuses.INVITED}>
					<Select defaultValue={hspStatuses.INVITED} disabled={true}>
						{Object.values(hspStatuses).map((item, index) => {
							return (
								<Select.Option key={index} value={item}>
									{item}
								</Select.Option>
							)
						})}
					</Select>
				</Form.Item>

				<Form.Item name="summary" label={lang('summary')}>
					<Input.TextArea showCount rows={3} maxLength={280} />
				</Form.Item>

				<Divider />
				<Input.Group>
					<div className="doubleFormItem">
						<div style={{ flexGrow: 1, width: '70%' }}>
							<Form.Item name="HP_street" label={lang('street-name')}>
								<Input />
							</Form.Item>
						</div>
						<div style={{ flexGrow: 1 }}>
							<Form.Item name="HP_streetnr" label={lang('street-number')}>
								<Input />
							</Form.Item>
						</div>
					</div>
				</Input.Group>
				<Form.Item name="HP_zip" label={lang('zip-code')}>
					<Input />
				</Form.Item>
				<Form.Item name="HP_city" label={lang('city')}>
					<Input />
				</Form.Item>
				<Form.Item name="HP_phone" label={lang('phone-number')}>
					<Input />
				</Form.Item>
				<Form.Item name="website" label={lang('website')}>
					<Input />
				</Form.Item>

				<Divider />

				<Form.Item name="service" label={lang('service')}>
					<Input.TextArea rows={8} />
				</Form.Item>

				<Divider />

				<div className="hsp-profile-section">
					<div className="hsp-profile-subsection">
						<h2>{lang('nation-wide-hsp')}</h2> <div>{lang('nation-wide-explanation')}</div>
					</div>
					<Form.Item initialValue={false} name="national_switch" value={nationwideRadio}>
						<Radio.Group onChange={(e) => handleRadioSelect(e)}>
							<Radio value={false}>{lang('regional-hsp')}</Radio>
							<Radio value={true}>{lang('nationwide-hsp')}</Radio>
						</Radio.Group>
					</Form.Item>
				</div>

				<Divider />

				<div className="hsp-profile-subsection">
					<h2>{lang('hsp-level')}</h2> <div>{lang('hsp-level-explanation')}</div>
				</div>
				<div style={{marginLeft: '35%'}} className="hsp-level-select">
					<Card
						className={selectedCard === '1' ? 'selected-level' : ''}
						headStyle={{ fontSize: '3em', textAlign: 'center' }}
						title={lang('level-1')}
						hoverable
						onClick={() => handleCardSelect({ card: '1' })}>
						<div className="center-icons">
							<Icon type="mama-hands" className="mama-no-outline" />
						</div>
						<div className="hsp-level-body">
							<div className="hsp-features">
								<ul>
									<li>{lang('level-1-features')}</li>
								</ul>
							</div>
							<AButton style={{marginLeft: '35%'}} type="primary" block>
								{lang('select')}
							</AButton>
						</div>
					</Card>
					{/* <Card
						className={selectedCard === '2' ? 'selected-level' : ''}
						title={lang('level-2')}
						headStyle={{ fontSize: '3em', textAlign: 'center' }}
						hoverable
						onClick={() => handleCardSelect({ card: '2' })}>
						<div className="center-icons">
							<Icon type="mama-hands" className="mama-no-outline" />
							<Icon type="mama-chat" />
							<Icon type="mama-call" className="mama-outline" />
						</div>
						<div className="hsp-level-body">
							<div className="hsp-features">
								<ul>
									<li>{lang('level-1-features')}</li>
									<li>{lang('level-2-features')}</li>
								</ul>
							</div>
							<AButton type="primary" block>
								{lang('select')}
							</AButton>
						</div>
					</Card> */}
				</div>
				<Form.Item
					hidden
					name="level"
					initialValue={'2'}
					rules={[
						{
							required: true,
							message: lang('missing-input')
						}
					]}>
					<Input hidden />
				</Form.Item>
				<Divider />

				<CategorySelectCard
					setRequestedContentCategories={setRequestedContentCategories}
					requestedContentCategories={requestedContentCategories}
					setRequestedHSPCategories={setRequestedHSPCategories}
					requestedHSPCategories={requestedHSPCategories}
				/>
				<Divider />
				<div className="hours-container">
					<OpeningHours day="monday" />
					<OpeningHours day="tuesday" />
					<OpeningHours day="wednesday" />
					<OpeningHours day="thursday" />
					<OpeningHours day="friday" />
					<OpeningHours day="saturday" />
					<OpeningHours day="sunday" />
				</div>
			</Form>
		</>
	)

	return (
		<PageHeader
			title={lang('health-service-provider-management')}
			footer={contextSwitcherTab()}
			extra={
				context === contexts.HP_LIST ? (
					<>
						<div className="topbar-wrapper">
							<Button
								txt={lang('onboard-HP')}
								type="primary"
								size="large"
								onClick={() => {
									setOpenOnboardHP(true)
								}}
							/>
						</div>

						<Drawer
							width={'80%'}
							open={openOnboardHP}
							disabled={updating}
							onOK={() => {
								HSPCreationForm.validateFields().then((values) => {
									const onboardingToast = toast.loading(lang('onboarding-hsp'))
									if (
										!requestedContentCategories ||
										requestedContentCategories.length === 0 ||
										!requestedHSPCategories ||
										requestedHSPCategories.length === 0
									) {
										toast.update(onboardingToast, {
											render: lang('missing-content-or-hsp-categories'),
											type: 'error',
											isLoading: false,
											autoClose: true
										})
										return
									}
									setUpdating(true)

									let preselectedHSPCategories =
										requestedHSPCategories &&
										requestedHSPCategories.map((category) => {
											return {
												id: category.id
											}
										})
									let preselectedContentCategories =
										requestedContentCategories &&
										requestedContentCategories.map((category) => {
											return {
												id: category.id
											}
										})
									let newBody = {
										name: values.HP_name,
										street: values.HP_street,
										streetnr: values.HP_streetnr,
										zip: values.HP_zip,
										city: values.HP_city,
										phone: values.HP_phone,
										email: values.email,
										service: values.service,
										summary: values.summary,
										website: values.website,
										requested_hsp_categories: preselectedHSPCategories,
										requested_content_categories: preselectedContentCategories,
										available: populateHoursArray(
											values.sunday_hours,
											values.monday_hours,
											values.tuesday_hours,
											values.wednesday_hours,
											values.thursday_hours,
											values.friday_hours,
											values.saturday_hours
										),
										image: values?.new_display_picture?.id,
										consent_signed: false,
										level: values.level,
										status: values.status,
										nationwide: values.national_switch,
										clinic_id: globals?.selectedCenter
									}

									backend.healthProvider({
										type: backend.type.CREATE,
										body: JSON.stringify(newBody),
										cb: (hsp) => {
											toast.update(onboardingToast, {
												render: lang('hsp-created'),
												type: 'success',
												isLoading: false,
												autoClose: true
											})
											if (hsp?.hspId) {
												hspSubcategories.forEach((subcategory) => {
													let match = requestedHSPCategories.find((category) => parseInt(category.id) === subcategory.id)
													if (match) {
														let new_content = subcategory.item.map((hsp) => parseInt(hsp.link.split('/')[2]))
														new_content.push(hsp.hspId)
														let body = { title: subcategory.title, new_content: new_content }
														body = JSON.stringify(body)
														backend.HSPCategories({
															type: backend.type.UPDATE,
															id: subcategory.id,
															body: body
														})
													}
												})

												cmsSubcategories.forEach((subcategory) => {
													let match = requestedContentCategories.find(
														(category) => parseInt(category.id) === subcategory.id
													)
													if (match) {
														let new_content = subcategory.item.map((hsp) => {
															return { key: parseInt(hsp.link.split('/')[2]), type: { title: hsp.link.split('/')[1] } }
														})
														new_content.push({ key: hsp.hspId, type: { title: 'hsps' } })
														let body = { title: subcategory.title, new_content: new_content }
														body = JSON.stringify(body)
														backend.cmsCategories({
															type: backend.type.UPDATE,
															id: subcategory.id,
															body: body
														})
													}
												})
											}
											setNewContent(newContent + 1)
											setOpenOnboardHP(false)
											setUpdating(false)
										},
										cbError: () => {
											toast.update(onboardingToast, {
												render: lang('hsp-creation-failed'),
												type: 'error',
												isLoading: false,
												autoClose: true
											})
											setUpdating(false)
										}
									})
									setSelectedThumbnailUrl(null)
								})
							}}
							onClose={() => {
								setOpenOnboardHP(false)
							}}
							text={{ title: lang('onboard-HP'), ok: lang('save'), cancel: lang('cancel') }}>
							<Skeleton hide={!updating}>{onboardingForm()}</Skeleton>
						</Drawer>
					</>
				) : (
					<>
						<div className="topbar-wrapper">
							<Button
								txt={lang('create-category')}
								type="primary"
								size="large"
								style={{ marginRight: '10px' }}
								onClick={() => {
									setOpenCreateCategory(true)
								}}
							/>
						</div>
						<Drawer
							width={'80%'}
							open={openCreateCategory}
							onOK={() => {
								categoryForm.validateFields().then((values) => {
									const loadingToast = toast.loading(lang('in-process'), { closeOnClick: true })
									let body = {
										...values
									}
									body = JSON.stringify(body)
									backend.HSPCategories({
										type: backend.type.CREATE,
										body,
										cb: () => {
											toast.update(loadingToast, {
												render: lang('category-created'),
												type: 'success',
												isLoading: false,
												closeOnClick: true,
												autoClose: 5000
											})
											setOpenCreateCategory(false)
											categoryForm.resetFields()
											setFetchCategories((fetchCategories) => fetchCategories + 1)
											setResetCategoryForm((resetCategoryForm) => resetCategoryForm + 1)
										},
										cbError: () => {
											toast.update(loadingToast, {
												render: lang('an-error-has-occured'),
												type: 'error',
												isLoading: false,
												closeOnClick: true,
												autoClose: 5000
											})
										}
									})
								})
							}}
							onClose={() => {
								setOpenCreateCategory(false)
								setResetCategoryForm((resetCategoryForm) => resetCategoryForm + 1)
							}}
							text={{ title: lang('create-category'), ok: lang('save'), cancel: lang('cancel') }}>
							<HSPCategoryCreator key={resetCategoryForm} form={categoryForm} />
						</Drawer>
					</>
				)
			}>
			<PageContent>
				<div className="container-xl">
					<div className="row row-deck row-cards">
						<div className="col-sm-12 col-lg-12">
							{context === contexts.HP_LIST && (
								<HPList
									hspStatuses={hspStatuses}
									newContent={newContent}
									setNewContent={setNewContent}
									healthProviders={healthProviders}
									cmsSubcategories={cmsSubcategories}
									hspSubcategories={hspSubcategories}
									loading={loading}
								/>
							)}
							{context === contexts.HP_CATEGORIES && <HSPCategories loading={loading} fetchCategories={fetchCategories} />}
						</div>
					</div>
				</div>
			</PageContent>
		</PageHeader>
	)
}

export { HealthServiceProviders, populateHoursArray }
